import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomepageLayout from "./layouts/HomepageLayout";
import OphthGptLayout from "./layouts/OphthGptLayout";
import AboutUsLayout from "./layouts/AboutUsLayout";
import EyeGptLayout from "./layouts/EyeGptLayout";
import FileToReportLayout from "./layouts/FileToReportLayout";
import { ophthalmologyTools } from "./layouts/SiteHeader";
import PrivacyPolicyLayout from "./layouts/PrivacyPolicyLayout";
import TermsAndConditionsLayout from "./layouts/TermsAndConditionsLayout";
// import ContactUs from './pages/ContactUs';
// Import other pages/components as needed

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomepageLayout />} />
        <Route path="/about-us" element={<AboutUsLayout />} />
        <Route path="/ophthgpt" element={<EyeGptLayout />} />
        {/* <Route path="/eyegpt" element={<EyeGptLayout />} /> */}
        <Route path="/privacy-policy" element={<PrivacyPolicyLayout />} />
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditionsLayout />}
        />
        <Route path="/ophthgpt" element={<OphthGptLayout />} />
        <Route path="/eyegpt" element={<EyeGptLayout />} />
        {/* Define other routes with their respective elements */}
        <Route path="*" element={<HomepageLayout />} />
        {/* File uploader layouts */}
        <Route
          path="/image-analysis"
          element={
            <FileToReportLayout
              headerText={"Image Analysis"}
              type={"image"}
              maxFiles={5}
            />
          }
        />
        {ophthalmologyTools.map((tool) => (
          <Route
            key={tool.link}
            path={tool.link}
            element={<FileToReportLayout type={"image"} maxFiles={5} />}
          />
        ))}
        {/* BOTS */}
        <Route
          path="/ophthalmic-scribe"
          element={
            <FileToReportLayout
              headerText={"Ophthalmic Scribe"}
              type={"audio"}
              maxFiles={1}
            />
          }
        />
        <Route
          path="/ophthalmic-triage"
          element={
            <EyeGptLayout
              headerText={"Ophthalmic Triage"}
              assistantName={"triage"}
            />
          }
        />
        <Route
          path="/refractive-surgery"
          element={
            <FileToReportLayout
              headerText={"Refractive Surgery Advisor"}
              type={"image"}
              maxFiles={5}
            />
          }
        />
        <Route
          path="/wavefront-optimized-treatment-plan"
          element={
            <FileToReportLayout
              headerText={"Wavefront Optimized Treatment Plan"}
              type={"image"}
              maxFiles={5}
            />
          }
        />
        <Route
          path="/practice-information-agent"
          element={
            <EyeGptLayout
              headerText={"Practice Information Agent (Coming soon!)"}
              assistantName={"practiceInformationAgent"}
            />
          }
        />
        <Route
          path="/previsit-interview"
          element={
            <EyeGptLayout
              headerText={"Ophthalmic Pre-Visit Interview"}
              subheaderText={"Say hello to start the previsit interview."}
              assistantName={"previsitInterview"}
            />
          }
        />
        {/* OPHTHALMIC SCHOLAR */}
        <Route path="/ophthalmic-scholar" element={<EyeGptLayout />} />
        <Route
          path="/ophthalmic-study-guide"
          element={
            <EyeGptLayout
              headerText={"Ophthalmic Study Guide"}
              assistantName={"ophthalmicImageStudyGuide"}
            />
          }
        />
        <Route
          path="/research-assistant"
          element={
            <EyeGptLayout
              headerText={"OculogyX Research Assistant"}
              assistantName={"researchAssistant"}
            />
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRouter;
