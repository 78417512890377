import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "../App.css";

import awsExports from "../aws-exports";
import ImageBannerWithText from "../components/ImageBannerWithText";
import { getSessionJwt } from "../util/utilities";
import { useMediaQuery } from "@mui/material";
import { ENDPOINTS } from "../util/constants";
import FileUpload from "../components/FileUpload";
import MasterLayout from "./MasterLayout";

Amplify.configure(awsExports);

const SuggestedPrompts = ({ setAdditionalPrompt, hidePrompts, prompts }) => {
  const handlePromptClick = (prompt) => {
    setAdditionalPrompt(prompt);
    hidePrompts();
  };

  return (
    prompts.length > 0 && (
      <Box sx={{ textAlign: "center", marginY: 2 }}>
        <Typography variant="h6" gutterBottom>
          Suggested Prompts
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {prompts.map((prompt, index) => (
            <Grid item key={index}>
              <Button
                variant="outlined"
                onClick={() => handlePromptClick(prompt)}
                sx={{
                  minWidth: 200,
                  padding: "15px 20px",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                  border: "1px solid #ddd",
                  textAlign: "center",
                  fontSize: "14px",
                  color: "#333",
                  backgroundColor: "#fff",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                {prompt}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  );
};

export default function FileToReportLayout(props) {
  const {
    headerText,
    isLoading,
    setIsLoading,
    responseText,
    setResponseText,
    setMessages,
    waitingAfterDone,
    setWaitingAfterDone,
    type,
    maxFiles,
  } = props;
  const [subscriptionStatus, setSubscriptionStatus] = useState("PENDING");
  const isMobile = useMediaQuery("(max-width:600px)");
  const [authActivity, setAuthActivity] = useState(0);
  const [showPrompts, setShowPrompts] = useState(true);
  const [additionalPrompt, setAdditionalPrompt] = useState("");

  const getCustomPrompts = (headerText) => {
    switch (headerText) {
      case "Image Analysis":
        return [
          "Analyze & Report image",
          "Analyze Nultiple Images and Report Changes",
          "Analyze a Document",
          "Merge Documents",
        ];
      case "Ophthalmic Scribe":
        return [
          "Full Chart Note",
          "SOAP Follow up Note",
          "Dictated Note",
          "Letter to referring doctor",
          "Note to Staff",
          "Full Chart Note, Letter to Referring Doctor, and Note to Staff",
        ];
      case "Refractive Surgery Advisor":
        return [
          "Am I candidate for PRK?",
          "Am I a candidate for Lasik?",
          "Am I a candidate to ICL?",
          "Should I consider a Refractive Lens Exchange?",
        ];
      case "Wavefront Optimized Treatment Plan":
        return [
          "Distance Correction OU",
          "Monovision Near OD",
          "Monovision Near OS",
          "Near Target =",
        ];
      default:
        return [];
    }
  };

  const getCustomUploadText = (headerText) => {
    switch (headerText) {
      case "Wavefront Optimized Treatment Plan":
        return "Import Pentacam Maps, PDFs of Clinic Chart Notes";
      case "Refractive Surgery Advisor":
        return "Upload Pentacam Scans, Autorefraction, and Argos";
      default:
        return "";
    }
  };
  const customPrompts = getCustomPrompts(headerText);
  const customText = getCustomUploadText(headerText);

  useEffect(() => {
    if (responseText && waitingAfterDone && isLoading) {
      const apiMessage = {
        text: responseText,
        sender: "gpt",
      };
      setMessages((prevMessages) => [...prevMessages, apiMessage]);
      setResponseText("");
      setWaitingAfterDone(false);
      setIsLoading(false);
    }
  }, [waitingAfterDone]);

  const createCheckoutAndRedirect = async (e) => {
    e.preventDefault();
    try {
      const jwtToken = await getSessionJwt();

      const response = await fetch(
        `${ENDPOINTS.LAMBDA_ENDPOINT}/create-checkout`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      const data = await response.json();
      const checkoutURL = data.results.checkoutURL;
      if (checkoutURL) {
        window.location.href = checkoutURL;
      }
    } catch (error) {
      console.error("Error creating checkout. Message:", error);
    }
  };

  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      const jwtToken = await getSessionJwt();
      if (!jwtToken) {
        setSubscriptionStatus("Refresh page!");
        return;
      }
      const response = await fetch(
        `${ENDPOINTS.LAMBDA_ENDPOINT}/get-subscription-status`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );
      const data = await response.json();
      if (!data.results) {
        console.error(
          "Error fetching subscription status. Message:",
          data.message
        );
        setSubscriptionStatus("Error. Refresh page!");
        return;
      }
      setSubscriptionStatus(data.results.subscriptionStatus);
    };
    fetchSubscriptionStatus();
  }, [authActivity]);

  const hubListenerCancel = Hub.listen("auth", (data) => {
    setAuthActivity((prev) => prev + 1);
    if (data.payload.event === "signedIn") {
      hubListenerCancel();
    }
  });

  return (
    <MasterLayout>
      <ImageBannerWithText
        header={headerText}
        subHeader="An AI digital assistant for Ophthalmic Care professionals. "
        imgLink="https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/eye-with-binary-data.jpeg"
        imgAltText="EyeGpt"
        height={isMobile ? 95 : 150}
      />
      <Authenticator>
        {({ signOut, user }) => (
          <main>
            <Grid container alignItems="center" sx={{ bgcolor: "#808080" }}>
              <Grid item xs={12} sm={1.5} sx={{ textAlign: "center" }}>
                <div className={!isMobile && "gpt-sidebar-container"}>
                  <br />
                  <Typography variant="h7" gutterBottom>
                    Welcome back, <b>{user.username}</b>
                  </Typography>
                  {!isMobile && <br />}
                  <br />
                  <Typography variant="h7" gutterBottom>
                    <b>
                      {subscriptionStatus === true
                        ? "ACTIVE"
                        : subscriptionStatus === false
                        ? "INACTIVE"
                        : subscriptionStatus}
                    </b>
                  </Typography>
                  {!isMobile && <br />}
                  <br />
                  {subscriptionStatus === true && (
                    <Button
                      href="https://billing.stripe.com/p/login/3csaHQcOy9G2g6c6oo" //prod
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        bgcolor: "#103444",
                        color: "white",
                        fontWeight: "bold",
                        marginTop: "20px",
                      }}
                    >
                      Manage Subscription
                    </Button>
                  )}
                  {subscriptionStatus === false && (
                    <Button
                      sx={{
                        bgcolor: "#103444",
                        color: "white",
                        fontWeight: "bold",
                        marginTop: "20px",
                      }}
                      onClick={createCheckoutAndRedirect}
                    >
                      Get a Subscription
                    </Button>
                  )}
                  <Button
                    sx={{
                      bgcolor: "#6aa0a3",
                      color: "white",
                      fontWeight: "bold",
                      marginTop: "20px",
                    }}
                    onClick={signOut}
                  >
                    Sign Out
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={10.5}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  bgcolor: "white",
                }}
              >
                <Grid>
                  <Typography
                    variant={!isMobile ? "h4" : "h5"}
                    gutterBottom
                    sx={{ p: "20px" }}
                  >
                    Generate a report by uploading your appropriate file(s).
                  </Typography>
                  <FileUpload
                    type={type ?? "image"}
                    maxFiles={maxFiles}
                    additionalPrompt={additionalPrompt}
                    setAdditionalPrompt={setAdditionalPrompt}
                    customText={customText}
                  />

                  {showPrompts && (
                    <SuggestedPrompts
                      setAdditionalPrompt={setAdditionalPrompt}
                      hidePrompts={() => setShowPrompts(false)}
                      prompts={customPrompts}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </main>
        )}
      </Authenticator>
    </MasterLayout>
  );
}
